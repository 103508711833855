<template>
<v-card>
        <div class="d-flex">
          <v-card-title>{{ detailsNota.resumen }}</v-card-title>
        </div>
        <v-divider></v-divider>
        <div class="pa-2">
          <v-card-text v-html="detailsNota.texto"></v-card-text>
        </div>
        <v-divider></v-divider>
        <div class="pa-2">
          <v-card-text>
            {{ detailsNota.usuario }}
            -
            {{ parseDate(detailsNota.fechaCambio, false, true, true) }}
          </v-card-text>
        </div>
        <v-divider></v-divider>
        <v-card-actions v-if="detailsNota.tipo == 'Alerta'">
          <v-spacer></v-spacer>
          <v-btn v-if="detailsNota.enviado == 0" color="error" text @click.stop="anularAlerta">
            <v-icon left>mdi-bell-remove-outline</v-icon>
            Anular
          </v-btn>
          <v-btn v-if="detailsNota.enviado == 1 && detailsNota.apagado == 0" color="primary" text @click.stop="apagarAlerta">
            <v-icon left>mdi-bell-sleep-outline</v-icon>
            Apagar
          </v-btn>
        </v-card-actions>
      </v-card>
</template>

<script>
import { parseDate } from "@/utils/index.js"

export default {
  props: {
    detailsNota: Object
  },
  methods: {
    parseDate,
    async anularAlerta() {
      const {data} = await axios({
        url: `${process.env.VUE_APP_API_URL}/cronograma/anularAlerta.php`,
        method: 'POST',
        data: {
          idNota: this.detailsNota.idNota
        }
      })
      this.$emit('close');
    },
    async apagarAlerta() {
      const {data} = await axios({
        url: `${process.env.VUE_APP_API_URL}/cronograma/apagarAlerta.php`,
        method: 'POST',
        data: {
          idNota: this.detailsNota.idNota
        }
      })
      this.$emit('close');
    }
  }
}
</script>